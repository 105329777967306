.options {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    box-shadow: #7cdedc 1px 3px;
    height: 3rem;
}

.option {
    justify-content: end;
    font-size: 2rem;
    text-decoration: none;
    color: #fff;
    font-size: 110%;
    background-color: #004E89;
    padding: .5rem;
}

.option:hover {
    color: #004e89;
    font-size: 30px;
    background-color: #a9b3ce;
}

.option:active {
    color: #004e89;
    background-color: #a9b3ce;
}


@media screen and (max-width: 750px) {
    .options {
        display: none;
      }
}