footer {
    background-color: #9e788f;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    max-height: 500px;
}

.footer-menu {
    padding-bottom: 10px;
}

.footer-option {
    text-decoration: none;
    color:white;
}

.social-icon {
    border-radius: 3px;
    max-height: 5%;
    max-width: auto;
}