.top-intro {
    background-image: linear-gradient(#9e788f, #004E89);
    background-color: #004E89;
    height: 10%;
    color: #fff;
    margin: -1%;
    padding: 3%;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    text-align: center;
}

.logoImg {
    
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.logoImg:hover {
    height: 110px
}

h1 {
    font-family: 'Mountains of Christmas', cursive;
    letter-spacing: 2px;
    font-size: 80px;
    color: #fff;
    text-align: center;
}


@media screen and (max-width: 1673px){
    h1 {
        font-size: 70px;
    }
}

@media screen and (max-width: 1415px){
    h1 {
        font-size: 60px;
    }
}

@media screen and (max-width: 1157px){
    h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 1074px) {
    .top-intro {
        grid-template-columns: .5fr 2fr 1fr;
    }
}