.image-container {
    max-width: 90%;
    height: auto;
    background-color: #004e89;
    border: #004e89 3px solid;    
    padding: 3%;
    margin: 2%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

}

.image-display {
    height:20rem;
    width: auto;
    margin: 2%;
}

.image-display:hover {
    height: 30rem;
    width: auto;
}